.container {
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-width: 3072px !important;
  padding-right: 0px!important;
  padding-left: 0px!important;
  
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px!important;
  padding: 0px!important;
  flex-direction: column;
}
.buttonContainer .actionButton {
  background-color: black;
  font-family: 'Rubik', sans-serif!important;
  color: white;
  border-radius: 50px;
  box-shadow: none;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 500;
  padding: 25px 35px 25px 35px;
}
.buttonContainer .actionButton:hover {
  background-color: #4d4d4d!important;
  border-color: #4d4d4d;
}
.modal {
  position: absolute;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  min-width: 350px;
  max-width: 500px;
  max-height: 800px;
  min-height: 500px;
  background-color: white;
  border-radius: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.inLineContainer {
  display: flex;
  justify-content:center;
  align-items: center;
}
.inLineContainer .inLineCount {
  font-family: Rubik, sans-serif !important;
  text-transform: none;
  font-weight:bolder;
  color: black;
  font-size: 20px;
  margin-right: 2px;
}
.inLineContainer .peopleInLine {
  color:grey;
  margin-left: 0.2em !important;
  font-size: 20px;
}
.mainContainer {
  padding-bottom: 100px;
}

@media(max-width: 500px){
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .buttonContainer .actionButton {
    background-color: black;
    font-family: 'Rubik', sans-serif!important;
    color: white;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
  }
  .buttonContainer .actionButton:hover {
    background-color: #4d4d4d!important;
    border-color: #4d4d4d;
  }
  .inLineCount {
    font-family: Rubik, sans-serif !important;
    text-transform: none;
    font-weight:bolder;
    color: black;
    margin-right: 2px;
  }
  .inLineContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding-top: 10px!important;
  }
  .mainContainer {
    padding-bottom: 84px;
  }
  .inLineContainer .peopleInLine {
    font-family: Rubik, sans-serif !important;
    color:grey;
    margin-left: 0.1em !important;
    font-size:16px;
    font-weight: 400;
  }
  .inLineContainer .inLineCount {
    font-family: Rubik, sans-serif !important;
    text-transform: none;
    font-weight:bold;
    color: black;
    font-size: 17px;
    margin-right: 2px;
  }
}
@media (min-width:501px) and (max-width:800px) {
  .mainContainer {
    padding-bottom: 80px;
  }
  .buttonContainer .actionButton {
    background-color: black;
    font-family: 'Rubik', sans-serif!important;
    color: white;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
  }
}