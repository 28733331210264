.singupContainer {
    padding: 20px;
}
.titleFontContainer {
    padding-top: 20px;
    padding-bottom: 30px;
}
.titleFont {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 34px;
    letter-spacing: '0.035em';
    line-height: '45px';
}
.textField {
    width: 100%;
    background-color:#f6f6f6; 
    height: 70px;
    border-radius: 5px; 
    padding-left: 20px;
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.buttonContainer .actionButton {
    background-color: black;
    color: white;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    margin-top: 25px;
    font-family: 'Rubik', sans-serif!important;
    margin-bottom: 5px;
    font-size: 17px;
    padding: 19px 40px 19px 40px;
    max-width: 80%;
}
.buttonContainer .actionButton:hover {
    background-color: #4d4d4d!important;
    border-color: #4d4d4d;
  }
.formContainer{
    padding-left: 20px;
    padding-right: 20px;
}
.error {
    font-family: 'Rubik', sans-serif!important;
    text-transform: none;
    font-size: 15px;
    font-weight: 400;
    line-height: auto;
    padding: 0px!important;
    color: #808080;
    margin-bottom: 25px;
}
.buttonContainer {
    flex-direction: column;
}
@media(max-width: 500px){
    .singupContainer {
        padding: 20px;
    }
    .titleFontContainer {
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .titleFontContainer  .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 34px;
        line-height: auto;
    }
    .formContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
    }
    .buttonContainer .actionButton {
        background-color: black;
        color: white;
        border-radius: 50px;
        box-shadow: none;
        text-transform: none;
        margin-top: 25px;
        font-family: 'Rubik', sans-serif!important;
        margin-bottom: 20px;
        font-size: 17px;
        padding: 19px 40px 19px 40px;
        max-width: 100%;
    }
    .buttonContainer .actionButton:hover {
        background-color: #4d4d4d!important;
        border-color: #4d4d4d;
      }

}
    
@media (min-width:501px) and (max-width:800px) {
    .singupContainer {
        padding: 20px;
    }
    .titleFontContainer {
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .titleFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 34px;
        line-height: auto;
    }
    .formContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
    }
    .buttonContainer .actionButton {
        background-color: black;
        color: white;
        border-radius: 50px;
        box-shadow: none;
        text-transform: none;
        margin-top: 25px;
        font-family: 'Rubik', sans-serif!important;
        margin-bottom: 20px;
        font-size: 17px;
        padding: 19px 40px 19px 40px;
        max-width: 100%;
    }
    .buttonContainer .actionButton:hover {
        background-color: #4d4d4d!important;
        border-color: #4d4d4d;
      }
}
