.titleFontContainer {
    padding-top: '10px';
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .titleFontContainer .titleFont {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 52px;
    margin:0px;
    line-height: auto;
}
.titleContainermobile{
    display: none;
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.titleContainerBrowser {
    display: block;
}
.buttonContainer .actionButton {
    background-color: black;
    color: white;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 17px;
    padding: 19px 40px 19px 40px;
}
.img {
    max-width: 100%;
    padding-left: 2px;
}
.imgMobile {
    display: none;
}
.imgContainer {
    margin-top: 120px;
    margin-bottom: 16px;
}
.textWraper .p {
    font-family: 'Rubik', sans-serif!important;
    text-align: left;
    text-transform: none;
    font-size: 32px;
    font-weight: 400;
    line-height:40px;
    padding: 0px!important;
    color: #808080;
    margin: 0px!important;
}
.pContainer {
    padding-right: 0px;
}
.textContainer {
    padding-left:130px; 
}
.titleFontPicContainer .titleFontPic{
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 52px;
    line-height: auto;
    margin: 0px !important;
}

.textWraper .titleFontPicContainer {
    padding-bottom: 12px;
}

.lastFontContainer {
    padding-top: 150px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}
.lastFontContainer .titleFont {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 52px;
    line-height: auto;
}

.picSectionContainer{
    padding-left: 130px;
}
.picSectionContainer .textWraper {
    max-width: 57%;
}
 .paperWrapper {
    max-width: 40%; 
}
.videoContainer{
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
}
.video {
    width:572px;
    height:572px;
    border-radius: 20px;
}


@media(min-width: 401px) and (max-width: 500px){
    .titleFontPicContainer .titleFontPic{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 32px;
        line-height: 38px!important;
        margin: 0px !important;
    }
   
    .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px!important;
        padding: 0px!important;
        color: #808080;
        padding-right: 0px!important;
    }
    .titleFontContainer {
        padding-top: '10px';
        align-items: center;
        flex-direction: column;
        padding-bottom: 10px;
    }

    .titleFontContainer .titleFont {
        font-family: Rubik, sans-serif!important;
        text-transform: none;
        font-weight: 500;
        font-size: 36px;
        line-height: auto;
    }
    .lastFontContainer {
        margin-top: 80px;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 20px;    
    }
    .lastFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight:500;
        font-size: 36px;
        line-height: auto;
        text-align: center;
    }
    .paperWrapper {
        max-width: 80%;
    }
    .picSectionContainer .textWraper {
        padding-right: 40px!important;
        padding-left: 32px!important;
        max-width: 100%;

    }
    .picSectionContainer{
        padding-left: 0%;
    }
    .img {
        display: none;
    }
    .imgMobile{
        display: block;
        max-width: 100%;
    }
    .imgContainer {
        margin-top: 60px;
        margin-bottom: 43px;
        padding-left: 32px;
    }
    .textWraper .titleFontPicContainer {
        padding-bottom: 15px;
    }
    .video {
        width:350px;
        height:350px;
        border-radius: 20px;
    }
    .titleContainerBrowser{
        display: none;
    }
    .titleContainermobile {
        display: block;
        line-height: 38px;
    }
    
    

}
@media(max-width: 400px){
    .titleFontPicContainer .titleFontPic{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 32px;
        line-height: 38px!important;
        margin: 0px !important;
    }
    .titleContainerBrowser{
        display: none;
    }
    .titleContainermobile {
        display: block;
        line-height: 38px;
    }
   
    .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px!important;
        padding: 0px!important;
        color: #808080;
        padding-right: 0px!important;
        
    }
    .titleFontContainer {
        padding-top: '10px';
        align-items: center;
        flex-direction: column;
        padding-bottom: 28px;
    }

    .titleFontContainer .titleFont {
        font-family: Rubik, sans-serif!important;
        text-transform: none;
        font-weight: 500;
        font-size: 32px;
        line-height: auto;
    }
    .lastFontContainer {
        padding-top: 74px;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 74px;    
        padding-right: 22px;
        padding-left: 22px ;
    }
    .lastFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight:500;
        font-size: 32px;
        line-height: auto;
        text-align: center;
    }
    .paperWrapper {
        max-width: 100%;
    }
    .picSectionContainer .textWraper {
        padding-right: 40px!important;
        padding-left: 32px!important;

    }
    .picSectionContainer{
        padding-left: 0%;
    }
    .img {
        display: none;

    }
    .imgMobile{
        display: block;
        max-width: 100%;
    }
    .imgContainer {
        margin-top: 60px;
        margin-bottom: 20px;
        padding-left: 32px;
    }
    .picSectionContainer .textWraper {
        max-width: 100%;
    }
    .textWraper .titleFontPicContainer {
        padding-bottom: 12px;
    }
    .video {
        width:311px;
        height:311px;
        border-radius: 20px;
    }
    
   
}
    
@media (min-width:501px) and (max-width:800px) {
 
    .paperWrapper {
        max-width: 60%;
    }
    .picSectionContainer .textWraper {
        padding-left: 0px;
    }
    .imgContainer {
        margin-top: 55px;
        margin-bottom: 40px;
    }
    .titleFontPicContainer .titleFontPic{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 40px;
        line-height: 45px!important;
        margin: 0px !important;
    }
   
    .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 25px;
        font-weight: 400;
        line-height: auto!important;
        padding: 0px!important;
        color: #808080;
        max-width: 80%;
        
    }
    .titleFontContainer {
        padding-top: '10px';
        align-items: center;
        flex-direction: column;
        padding-bottom: 60px;
    }

    .titleContainerBrowser{
        display: none;
    }
    .titleContainermobile {
        display: block;
    }
   
    .titleFontContainer .titleFont {
        font-family: Rubik, sans-serif!important;
        text-transform: none;
        font-weight: 500;
        font-size: 40px;
        line-height: auto;
    }
    .lastFontContainer {
        margin-top: 80px;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 20px;    
    }
    .lastFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight:500;
        font-size: 36px;
        line-height: auto;
        text-align: center;
    }
    .paperWrapper {
        max-width: 45%;
    }
   
    .picSectionContainer{
        padding-left: 32px;
    }
    .img {
        display: none;
    }
    .imgMobile{
        display: block;
        max-width: 100%;
    }
    .imgContainer {
        margin-top: 30px;
        margin-bottom: 40px;
     
    }
    .picSectionContainer .textWraper {
        max-width: 100%;
    }
    .textWraper .titleFontPicContainer {
        padding-bottom: 15px;
    }
    .video {
        width:400px;
        height:400px;
        border-radius: 20px;
    }
    
}
@media (min-width:801px) and (max-width:1400px) {
    .paperWrapper {
        max-width: 50%;
    }  
    .picSectionContainer .textWraper {
        max-width: 70%;
    } 
    .imgContainer {
        margin-top: 120px;
        margin-bottom: 40px;
    }
}
    