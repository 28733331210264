.footerContainer {
    padding-top: 10px;

    padding-left: 130px;
    display: flex;
    flex-direction: column;
    align-items: left;
    color: white;
    text-transform: none;
    line-height: 45px;
}
.footerButtons{
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 400;
    font-size: 24px;
    line-height: auto!important;
    margin: 0px !important;

}
.footerButtonsCopyright{
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 400;
    font-size: 24px;
    line-height: auto!important;
    margin: 0px !important;
    color: #7d7d7d;
    text-transform: none; 
    padding-top: 20px; 
    padding-bottom: 130px;
}

@media(max-width: 400px){
    .footerContainer {
        padding-top: 10px;
        padding-left: 32px;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-transform: none;
        line-height: 30px;
    }
    .footerContainer .wrap{
        flex-direction: row;
        flex-wrap: nowrap;
        display: inherit;
    }
    .footerButtons{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: lighter;
        font-size: 18px;
        line-height: auto!important;
        margin: 0px !important;
        padding: 0px;
    }
    .footerButtonsCopyright{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: lighter;
        font-size: 18px;
        line-height: auto!important;
        margin: 0px !important;
        color: #7d7d7d;
        text-transform: none; 
        padding-top: 10px; 
        padding-bottom: 32px;
    }
}
@media (min-width:401px) and (max-width:500px) {
    .footerContainer {
        padding-top: 10px;
        padding-left: 32px;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-transform: none;
        line-height: 30px;
    }
    .footerContainer .wrap{
        flex-direction: row;
        flex-wrap: nowrap;
        display: inherit;
    }
    .footerButtons{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: lighter;
        font-size: 18px;
        line-height: auto!important;
        margin: 0px !important;
        padding: 0px;
    }
    .footerButtonsCopyright{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: lighter;
        font-size: 18px;
        line-height: auto!important;
        margin: 0px !important;
        color: #7d7d7d;
        text-transform: none; 
        padding-top: 10px; 
        padding-bottom: 32px;
    }
}

@media (min-width:501px) and (max-width:800px) {
    .footerContainer {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 32px;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-transform: none;
        line-height: 30px;
    }
    .footerContainer .wrap{
        flex-direction: row;
        flex-wrap: nowrap;
        display: inherit;
    }
    .footerButtons{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: lighter;
        font-size: 19px;
        line-height: auto!important;
        margin: 0px !important;
        padding: 0px;
    }
    .footerButtonsCopyright{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 400;
        font-size: 24px;
        line-height: auto!important;
        margin: 0px !important;
        color: #7d7d7d;
        text-transform: none; 
        padding-top: 20px; 
        padding-bottom: 32px;
    }
}