.headerContainer {
  padding: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}
.logoContainer {
  align-items: flex-start;

}
.logo {
  max-width: 25px;

}
.aboutButton {
  color: black!important;
  text-transform: none;
  display: inline;
}
.buttonContainer {
  justify-content: flex-end;
}
@media(max-width: 400px){
  .headerContainer {
    padding: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }

}
@media (min-width:401px) and (max-width:500px) {
  .headerContainer {
    padding: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }
}
@media (min-width:501px) and (max-width:800px) {
  .headerContainer {
    padding: 0px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }
}

