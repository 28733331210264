.waitlistContainer {
    padding: 30px;
    
}
.titleFontContainer {
  
    padding-bottom: 0px;
}
.titleFontContainer .titleFont {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 30px;
    letter-spacing: '0.035em';
    margin: 0px;
    line-height: '45px';
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.subTitle {
    font-family: 'Rubik', sans-serif!important;
    text-align: center;
    text-transform: none;
    font-size: 16px;
    padding-top: 0px;
    font-weight: 400;
    line-height: auto!important;
    color: #808080;   
}
.subTitle2 {
    font-family: 'Rubik', sans-serif!important;
    text-align: center;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    line-height: auto!important;
    color: #808080;
    margin: 0px;
}
.subTitle1 {
    font-family: 'Rubik', sans-serif!important;
    text-align: center;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    line-height: auto!important;
    color: #808080;
    margin:0px;
}
.logoSubTitle {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: 500;
    font-size: 16px;
    line-height: auto;
}

.buttonContainer .actionButton {
    background-color: black;
    color: white;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    margin-top: 40px;
    font-family: 'Rubik', sans-serif!important;
    margin-bottom: 5px;
    font-size: 17px;
    padding: 19px 50px 19px 50px;
    max-width: 60%;
}

.buttonContainer .actionButton:hover {
    background-color: #4d4d4d!important;
    border-color: #4d4d4d;
  }

.img {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 70px;
    height: 70px;
}

@media(max-width: 500px){
    .waitlistContainer {
        padding: 30px;
        
    }
    .titleFontContainer {
        
        padding-bottom: 0px;
    }
    .titleFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 30px;
        letter-spacing: '0.035em';
        margin: 0px;
        line-height: '45px';
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
    }
    .subTitle {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        padding-top: 0px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;   
    }
    .subTitle2 {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;
        margin: 0px;
    }
    .subTitle1 {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;
        margin:0px;
    }
    .logoSubTitle {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 16px;
        line-height: auto;
    }
    
    .buttonContainer .actionButton {
        background-color: black;
        color: white;
        border-radius: 50px;
        box-shadow: none;
        text-transform: none;
        margin-top: 40px;
        font-family: 'Rubik', sans-serif!important;
        margin-bottom: 5px;
        font-size: 17px;
        padding: 19px 30px 19px 30px;
        max-width: 80%;
    }
    
    .buttonContainer .actionButton:hover {
        background-color: #4d4d4d!important;
        border-color: #4d4d4d;
      }
    
    .img {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 70px;
        height: 70px;
    }

}
@media (min-width:501px) and (max-width:800px) {
    .waitlistContainer {
        padding: 30px;
        
    }
    .titleFontContainer {
        
        padding-bottom: 0px;
    }
    .titleFontContainer .titleFont {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 30px;
        letter-spacing: '0.035em';
        margin: 0px;
        line-height: '45px';
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
    }
    .subTitle {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        padding-top: 0px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;   
    }
    .subTitle2 {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;
        margin: 0px;
    }
    .subTitle1 {
        font-family: 'Rubik', sans-serif!important;
        text-align: center;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        line-height: auto!important;
        color: #808080;
        margin:0px;
    }
    .logoSubTitle {
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 16px;
        line-height: auto;
    }
    
    .buttonContainer .actionButton {
        background-color: black;
        color: white;
        border-radius: 50px;
        box-shadow: none;
        text-transform: none;
        margin-top: 40px;
        font-family: 'Rubik', sans-serif!important;
        margin-bottom: 5px;
        font-size: 17px;
        padding: 19px 30px 19px 30px;
        max-width: 80%;
    }
    
    .buttonContainer .actionButton:hover {
        background-color: #4d4d4d!important;
        border-color: #4d4d4d;
      }
    
    .img {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 70px;
        height: 70px;
    }
}
