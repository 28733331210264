.container {
    padding-right: 0px!important;
    padding-left: 0px!important;  
}

.thirdSectionContainer {
    padding-right: 130px;
   
}
.titleFont {
    font-family: 'Rubik', sans-serif!important;
    text-transform: 'none';
    font-weight: '900';
    font-size: '15px';
    letter-spacing: '0.035em';
    line-height: '45px';
    justify-content: stretch;
    text-justify: inter-word;
    font-stretch: condensed;
}
.img {
    max-width: 100%;
  
}
.imgMobile {
    display: none;
}
.imgContainer {
    padding-top: 140px;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-start;
}
.textWraper{
    padding-left: 130px;
    max-width: 75%;
    padding-bottom: 60px;
}
.textWraper .p1 {
    font-family: 'Rubik', sans-serif!important;
    text-align: left;
    text-transform: none;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: #808080;  
    
}
.textWraper .p2 {
    font-family: 'Rubik', sans-serif!important;
    text-align: left;
    text-transform: none;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: #808080;  
    margin-bottom: 150px;

}


.titleFontPicContainer .titleFontPic{
    font-family: 'Rubik', sans-serif!important;
    text-transform: none;
    font-weight: 500;
    font-size: 52px;
    text-align: left;
    line-height: auto;
    margin: 0px !important;
}
.titleFontPicContainer {
    padding-bottom: 12px;
}
.PContainerBrowser{
    display: block;
}
.PContainerMobile {
    display: none;
}

@media(min-width: 401px) and (max-width: 500px){
    .img {
        display: none;
     }
     .imgMobile {
         display: block;
         max-width: 100%;
         height: 300px;
     }
     .imgContainer {
         padding-top: 60px;
         padding-bottom: 43px;
         display: flex;
         justify-content: flex-start;
     }
     .titleFontPicContainer .titleFontPic{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 32px;
        text-align: left;
        line-height: 38px!important;
        margin: 0px !important;
    }
    .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 18px;
        font-weight:400;
        line-height: 25px!important;
        color: #808080;
        margin-left: 0%!important;
        padding-bottom: 0px;
    }
     .thirdSectionContainer .textWraper {
         padding-right: 0px;
     }
     .thirdSectionContainer {
         padding-right: 4%;
     }
     .titleFontPicContainer {
         padding-bottom: 15px;
     }
     .PContainerBrowser{
        display: none;
    }
    .PContainerMobile {
        display: block;
    }
    .textWraper{
        padding-left: 32px;
        max-width: 100%;
        padding-bottom: 60px;
    }
}

@media(max-width: 400px){
    .img {
       display: none;
    }
    .imgMobile {
        display: block;
        max-width: 100%;
    }
    .imgContainer {
        padding-top: 84px!important;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-start;
    }
    .titleFontPicContainer .titleFontPic{
        font-family: 'Rubik', sans-serif!important;
        text-transform: 'none';
        font-weight: 500;
        font-size: 32px;
        text-align: left;
        line-height: 38px!important;
        margin: 0px !important;
    }
    .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 18px;
        font-weight:400;
        line-height: 25px!important;
        color: #808080;
        margin-left: 0%!important;
        padding-bottom: 0px;
    }
    .thirdSectionContainer .textWraper {
        padding-left: 32px;
        padding-bottom: 84px;
        max-width: 100%;
    }
    .thirdSectionContainer {
        padding-right: 32px;
    }
    .titleFontPicContainer {
        padding-bottom: 12px;
    }
    .PContainerBrowser{
        display: none;
    }
    .PContainerMobile {
        display: block;
    }
    
    
}
@media (min-width:501px) and (max-width:800px) {
   
   
    .img {
        display: none;
     }
     .imgMobile {
         display: block;
         max-width: 100%;
     }
     .imgContainer {
         padding-top: 74px!important;
         padding-bottom: 43px;
         display: flex;
         justify-content: flex-start;
     }
     .titleFontPicContainer .titleFontPic{
         font-family: 'Rubik', sans-serif!important;
         text-transform: 'none';
         font-weight: 500;
         font-size: 32px;
         text-align: left;
         line-height: 45px!important;
         margin: 0px !important;
     }
     .textWraper .p {
        text-align: left;
        text-transform: none;
        font-size: 25px;
        font-weight: 400;
        line-height: auto!important;
        padding: 0px!important;
        color: #808080;
        max-width: 80%;
        
    }
    
     .textWraper .pContainer {
         padding-left: 10px;
     }
     .thirdSectionContainer .textWraper {
         padding-left: 32px;
         padding-bottom: 80px;
         max-width: 100%;
     }
     .thirdSectionContainer {
         padding-right: 32px;
     }
     .titleFontPicContainer {
         padding-bottom: 11px;
     }
     .PContainerBrowser{
        display: none;
    }
    .PContainerMobile {
        display: block;
    }
     
}
@media (min-width:801px) and (max-width:1399px) {
    .img {
        max-width: 100%;
        max-height: 937px!important;
    }
    .textWraper{
        padding-left: 130px;
        max-width: 70%;
        padding-bottom: 120px!important;
    }
    .textWraper .p {
        font-family: 'Rubik', sans-serif!important;
        text-align: left;
        text-transform: none;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        color: #808080;  
        
    }
    .PContainerBrowser{
        display: none;
    }
    .PContainerMobile {
        display: block;
    }
    .imgContainer {
        padding-top: 120px;
        padding-bottom: 48px;
        display: flex;
        justify-content: flex-start;
    }
}